import React from 'react';
import * as styles from './styles.module.scss';

export const Header = () => (
	<div className={styles.container}>
		<p className={styles.logo}>[ Krunge ]</p>
		<div>
			<svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
				<circle cx="21" cy="21" r="21" fill="#97F6CB" />
				<path d="M16.8 41.5799V29.3H14C10.134 29.3 7 26.166 7 22.3V18.0206C7 16.5181 8.21802 15.3 9.72058 15.3C11.5198 15.3 12.8269 17.0237 12.6213 18.8112C12.4261 20.5096 12.4559 22.3247 13.3 23C14.0056 23.5645 15.394 23.6738 16.8 23.6031V13.2C16.8 10.8804 18.6804 9 21 9C23.3196 9 25.2 10.8804 25.2 13.2V30.6031C26.606 30.6738 27.9944 30.5645 28.7 30C29.5441 29.3247 29.5739 27.5096 29.3787 25.8112C29.1731 24.0237 30.4802 22.3 32.2794 22.3C33.782 22.3 35 23.5181 35 25.0206V29.3C35 33.166 31.866 36.3 28 36.3H25.2V41.5799C23.8429 41.8554 22.4384 42 21 42C19.5616 42 18.1571 41.8554 16.8 41.5799Z" fill="#E0AEF5" />
			</svg>
		</div>
	</div>
);